import request from "../utils/request";

export function get_store_list_admin(data: JSON){
    return request({
        url: "/getStoreListAdmin",
        method: "post",
        data: data
    });
}

export function get_store_detail_admin(data: JSON){
    return request({
        url: "/getStoreDetailAdmin",
        method: "post",
        data: data
    });
}

export function delete_store_admin(data: JSON) {
    return request({
        url: "/deleteStoreAdmin",
        method: "post",
        data: data
    });
}

export function save_store_detail_admin(data: FormData){
    return request({
        url: "/saveStoreDetailAdmin",
        method: "post",
        data: data
    });
}

export function get_store_basic_info_admin(data: JSON) {
    return request({
        url: "/getStoreBasicInfoAdmin",
        method: "post",
        data: data
    });
}

export function get_lng_lat(data: JSON) {
    return request({
        url: "/getLngLat",
        method: "post",
        data: data
    });
}