import request from "../utils/request";

export function get_store_product_list_admin(data: JSON){
    return request({
        url: "/getStoreProductListAdmin",
        method: "post",
        data: data
    });
}

export function get_store_product_detail_admin(data: JSON){
    return request({
        url: "/getStoreProductDetailAdmin",
        method: "post",
        data: data
    });
}

export function delete_store_product_admin(data: JSON) {
    return request({
        url: "/deleteStoreProductAdmin",
        method: "post",
        data: data
    });
}

export function save_store_product_detail_admin(data: FormData){
    return request({
        url: "/saveStoreProductDetailAdmin",
        method: "post",
        data: data
    });
}